<template>
  <div class="center">
    <topheader :type="activeIndex"></topheader>
    <div class="flex container base_w"> 
      <div class="leftnav">
        <p :class="{active: clickNavId == 1}" @click="clickNav(1)"> <span>个人资料</span> </p>
        <p :class="{active: clickNavId == 2}" @click="clickNav(2)"><span>我的钱包</span></p>
        <p :class="{active: clickNavId == 3}" @click="clickNav(3)"><span>我的咨询</span></p>
        <p :class="{active: clickNavId == 4}" @click="clickNav(4)"><span>我的收藏/关注</span></p>
        <p :class="{active: clickNavId == 5}" @click="clickNav(5)"><span>企业认证</span></p>
        <!-- <p v-show="!gerenshow" :class="{active: clickNavId == 6}" @click="clickNav(6)" ><span>会员中心</span></p> -->
        <p :class="{active: clickNavId == 6}" @click="clickNav(6)" ><span>会员中心</span></p>
        <p :class="{active: clickNavId == 7}" @click="clickNav(7)"><span>银行卡</span></p>
      </div>

      <div class="rightmain_center">
        <router-view />
      </div>
    </div>
   <!-- 页脚 -->
   <bottomfooter></bottomfooter>
  </div>
</template>

<script>
import topheader from '@/components/TopHeader.vue'
import bottomfooter from '@/components/Footer.vue'
import apiUrl from '@/api/login'

export default {
  name: 'center',
  components: { topheader, bottomfooter },
  data() {
    return {
       activeIndex: -1,
       clickNavId: 1, // 1个人资料 2 我的钱包 3我的咨询 4 我的收藏/关注 5 企业认证 6 会员中心 7 银行卡
       hash:'',
       gerenshow: true, // true 个人用户 false 企业用户
    };
  },
  watch:{
    $route() {
      this.int()
    }
  },
 
  mounted(){
    this.int();
    if(localStorage.getItem('userId')){
      this.getuserinfo();
    }
  },

 
  methods:{
    int(){
        this.hash = window.location.hash;
        if(this.hash.indexOf('gerenziliao') > -1){
          this.clickNavId = 1
        } else if(this.hash.indexOf('wodeqianbao') > -1 || this.hash.indexOf('tixian') > -1){
          this.clickNavId = 2
        } else if(this.hash.indexOf('zixun') > -1 || this.hash.indexOf('pingjia') > -1 || this.hash.indexOf('shouhou') > -1) {
          this.clickNavId = 3
        }else if(this.hash.indexOf('follow') > -1){
          this.clickNavId = 4
        }else if(this.hash.indexOf('authentify') > -1){
          this.clickNavId = 5
        }else if(this.hash.indexOf('member') > -1){
          this.clickNavId = 6
        }else if(this.hash.indexOf('card') > -1){
          this.clickNavId = 7
        }

        if(!localStorage.getItem('userId')){
          this.$router.push({
            path: '/index'
          });
          return;
        }

    },
    // 获取用户信息
    getuserinfo() {
      let data = {
        userId: localStorage.getItem('userId')
      }
      apiUrl.userInfo(data).then((res) => {
          if(res.code == 0){
            if(res.data.usertype == '企业用户'){
              this.gerenshow = false;
            }else {
              this.gerenshow = true;
            }
          } else {
            this.gerenshow = true;
          }
      });
    },
    clickNav(num){
      this.hash = window.location.hash;
      this.clickNavId = num;

      if(num == 1){
        if(this.hash.indexOf('gerenziliao') > -1){
          return
        }
        this.$router.push({
          path:'/gerenziliao'
        })
      } else if(num == 2){
          if(this.hash.indexOf('wodeqianbao') > -1){
            return
          }
          this.$router.push({
            path:'/wodeqianbao'
          })
      } else if(num == 3){
          if(this.hash.indexOf('zixun') > -1){
            return
          }
          this.$router.push({
            path:'/zixun'
          })
      } else if(num == 4){
          if(this.hash.indexOf('follow') > -1){
            return
          }
          this.$router.push({
            path:'/follow'
          })
      } else if(num == 5){
          if(this.hash.indexOf('authentify') > -1){
            return
          }
          this.$router.push({
            path:'/authentify'
          })
      } else if(num == 6){
          if(this.hash.indexOf('member') > -1){
            return
          }
          this.$router.push({
            path:'/member'
          })
      } else if(num == 7){
          if(this.hash.indexOf('card') > -1){
            return
          }
          this.$router.push({
            path:'/card'
          })
      }
    }
  }
};
</script>

<style lang='scss' scoped>
.center{
  background: #F8F8F8;
  .container{
    margin-top: 20px;
    margin-bottom: 33px;
  }
// 左边
.leftnav{
  width: 200px;
  height: 510px;
  background-color: #fff;
  color: #333333;
  text-align: center;
  border-radius: 16px;
  p{
    height: 35px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 35px;
    padding: 15px 0;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    span{
      display: inline-block;
      width:100%;
    }
    &:hover{
      color: $colorMain;
    }
  }
  p.active{
    background-color: #fff;
    color: $colorMain;
    font-weight: 550;
    span{
      box-sizing: border-box;
      border-left: 5px solid  $colorMain;
    }
  }

}

// 右边
.rightmain_center{
  // background-color: #fff;
  margin-left: 20px;
  box-sizing: border-box;
  width: 1216px;
}

}
</style>